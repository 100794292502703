/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Button } from 'antd';
import { IconFont } from '../../component/icon-font';
import './home.scss';
import moment from 'moment';
import "moment/locale/th";

const { Meta } = Card;
interface DataAppointment {
  appointmentId: string,
  hn: string,
  staffId: string,
  patientName: string,
  staffName: string,
  staffPic: string,
  appointmentDate: string,
  appointmentTime: string
}

interface HomeProps extends RouteComponentProps {
  status: string;
  onLeaveOrJoinSession: () => void;
  dataAppointment: DataAppointment;
}
const Home: React.FunctionComponent<HomeProps> = (props) => {
  moment.locale("th");
  const { history, status, onLeaveOrJoinSession } = props;
  const onCardClick = (type: string) => {
      history.push(`/${type}${location.search}`);
  };
  const [data, setData] = useState<DataAppointment>();
  const [chkMeeting, setChkMeeting] = useState<Boolean>(false);

  const featureList = [
    {
      key: 'video',
      icon: 'icon-meeting',
      title: 'นายแพทย์เมธี ภัคเวช',
      description: '16 เมษายน 2567 16.00 น.'
    },
    // {
    //   key: 'chat',
    //   icon: 'icon-chat',
    //   title: 'Session chat',
    //   description: 'Session Chat, Chat Priviledge'
    // },
    // {
    //   key: 'command',
    //   icon: 'icon-chat',
    //   title: 'Command Channel chat',
    //   description: 'Session Command Channel chat'
    // },
    // {
    //   key: 'subsession',
    //   icon: 'icon-group',
    //   title: 'Subsession',
    //   description: 'Open/Close Subsession, Assign/Move Participants into Subsession, Join/Leave Subsession'
    // },
    // {
    //   key: 'preview',
    //   icon: 'icon-meeting',
    //   title: 'Local Preview',
    //   description: 'Audio and Video preview'
    // }
  ];
  let actionText;
  if (status === 'connected') {
    actionText = 'ออกจากการประชุม';
  } else if (status === 'closed') {
    actionText = 'เข้าร่วมการประชุม';
  }

  const chkDateTimeTxt = (datetime:string) => {
    const params = new URLSearchParams(window.location.search);
    const testStatus = params.get("test");
    const qDoctor = params.get("doctor");

    const now = new Date();
    const dateAppointment = new Date(datetime);
    const minus10 = new Date(dateAppointment.getTime() - 10 * 60000);
    const plus1 = new Date(dateAppointment.getTime() + 60 * 60000);
    let txt = "";
    if(testStatus != "1"){
      if(qDoctor == null || qDoctor == ""){
        if(now < minus10){
          txt = "สามารถเข้าพบแพทย์ได้ตั้งแต่ เวลา "+ moment(minus10).format("HH:mm");
        }
      }
      
      if(now > plus1){
        txt = "ลิงค์หมดอายุ";
      }
    }
    return txt
  }

  const chkDateTimeBool = () => {
    const params = new URLSearchParams(window.location.search);
    const testStatus = params.get("test");
    const qDoctor = params.get("doctor");

    const now = new Date();
    const res = props.dataAppointment
    const datetime = res?.appointmentDate +" "+ res?.appointmentTime
    const dateAppointment = new Date(datetime);
    const minus10 = new Date(dateAppointment.getTime() - 10 * 60000);
    const plus1 = new Date(dateAppointment.getTime() + 60 * 60000);
    let chk = true;
    if(testStatus != "1"){
      if(qDoctor == null || qDoctor == ""){
        if(now < minus10){
          chk = false;
        }
      }
      
      if(now > plus1){
        chk = false;
      }
    }
    console.log(testStatus, qDoctor, chk);
    setChkMeeting(chk);
  }


  useEffect(()=>{
    setData(props.dataAppointment);
    chkDateTimeBool();
    const intervalId = setInterval(chkDateTimeBool, 60000);
    return () => clearInterval(intervalId);
  },[props.dataAppointment]);
  console.log(chkMeeting);
  return (
    <div>
      <div className="nav">
          <a href="https://morru.com/" className="navhome">
          <img src="./logo-full-clear2.png" width={120} height={43} alt="Home" />
          <span className='label-header text-header-color'>หมอรู้หมอคือคุณ</span>
        </a>
        {/* <div className="navdoc">
          <a
            href="https://marketplace.zoom.us/docs/sdk/video/web/reference"
            target="_blank"
            rel="noreferrer"
          >
            <span>API Reference</span>
          </a>

          <a
            href="https://marketplace.zoom.us/docs/sdk/video/web/build/sample-app"
            target="_blank"
            rel="noreferrer"
          >
            <span>Doc</span>
          </a>
        </div> */}
        {actionText && (
          <Button type="link" href="https://morru.com/" className="navleave" 
          //onClick={onLeaveOrJoinSession}
          >
            {actionText}
          </Button>
        )}
      </div>

      <div className="home">
        <h1 className='text-primary-color'>เข้าร่วมการประชุม</h1>
        <div className="feature-entry">
          {featureList.map((feature) => {
            const { key, icon, title, description } = feature;
            return (
              <Card
                // cover={<IconFont style={{ fontSize: '72px' }} type={icon} />}
                hoverable
                style={{ width: 320 }}
                className="entry-item"
                key={key}
              >
                <img crossOrigin="anonymous" src={data?.staffPic} style={{width:'200px',borderRadius:"3px"}} />
                <div className='text-primary-color' style={{marginTop:"10px",fontSize:"16px",fontWeight:500}}>{data?.staffName}</div>
                <div className='text-secondary-color' >{moment(data?.appointmentDate).format('DD MMM yyyy') +" "+ data?.appointmentTime}</div>
                {
                  (chkMeeting)?
                    <Button onClick={() => onCardClick(key)} style={{marginTop:"10px"}} className='btn-color'>เข้าร่วมการประชุม</Button>
                  :
                  <>
                  <span style={{color:"#D9534F"}}>{chkDateTimeTxt(data?.appointmentDate +" "+ data?.appointmentTime)}</span><br/>
                  </>
                }
                {/* <Meta title={title} description={description} /> */}

              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Home;
