import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'DwPGXk7nSP6X1Bxtv7C72Q',
  sdkSecret: 'LBASVM76CAmbFroZTjGs0OGyl24HUaTlPN7A',
  webEndpoint: 'zoom.us',
  topic: 'MPK Medical Meedting',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '1234',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  //userName: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
